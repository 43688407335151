import React from 'react'
import styled from 'styled-components'
const StyledDiv = styled.div`
width: 100%;
background:transparent;
display:flex;
justify-content:center;
align-items:center;
flex-direction: row;
background-color: #FFFCF6;

.h1 {
    color:red;
}
.textBox{
    width: 100%;
    background:transparent;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
}
.dietBox{
}
`

const DietSection = () =>{
    return(
        <StyledDiv>
            <div className="textBox">
                <h1>The ProLon Fasting Mimicking Diet</h1>
                <h3>ProLon helps unlock the deepest level of fasting</h3>
                <p>The program triggers “autophagy,” your body’s natural way of cleaning house.</p>
                <p>Your cells clean and recycle old and damaged parts, resulting in a profound benefit: cellular rejuvenation.</p>
            </div>
            <div className="dietBox">

            </div>
        </StyledDiv>
    )
}

export default DietSection
